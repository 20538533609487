import React from 'react'
import './cours.css'
import ReactPlayer  from 'react-player';

   


const python_1 = () => {
    return (
        <div className="App">
        <head><meta name="description" 
        content="Cours python"></meta>
        <title>Cours python</title></head>
        <h1 style={{margin:'100px'}}> Cours Python </h1>
        <div className="cours">
            <br></br>
            <br></br>
            <h2>Introduction : </h2>
            <br></br>
            <br></br>
                <h3>Histoire de python : </h3>
                <br></br>
                <br></br>
                <p>Python fut créé en 1989 par Guido Van Rossum, qui travaillait alors au Centrum voor Wiskunde en Informatica (CWI) d'Amsterdam, aux Pays-Bas.
                Il créa la première version lors d'une semaine de vacances,
                et publia la première version publique le 20 février 1991.</p>
                <br></br>
                <br></br>
                <br></br>
                <h3>Pourquoi Python ?</h3>
                <br></br>
                <br></br>
                <p>python est un langage très utilisé en Hacking, ainsi qu'en Machine Learning, Deep Learning, et Data Analysis.</p>
                <p>C'est également un langage relativement facile et qui permet énormément de choses.</p>
                <br></br>
                <h3>Comment installer python :</h3>
                <br></br>
                <p>Installer Python est très simple, il vous suffit d'aller sur le site <a style={{color:"white"}}href="http://python.org">python.org</a>, d'aller dans la section Downloads 
                et de séléctionner la dernière version pour la plateforme qui vous convient. Pour l'éditeur (ce avec quoi nous allons coder), vous pouvez utiliser
                celui installé par défaut, mais je vous conseille d'utiliser vscode, sur lequel j'ai également fait un cours sur comment l'Installer
                et les premières extensions à ajouter dont voici le  <a style={{color:"white"}}href="/vscode">lien</a></p>
                <br></br>
                <br></br>
                <h3>Premier programme python : </h3>
                <br></br>
                <p>Voici une vidéo vous montrant comment écrire et compiler votre premier programme python, sur vscode : </p>
                <p></p>
                <br></br>
                <br></br>
                <ReactPlayer 
                    controls
                    url="https://youtu.be/TKPJHcytm_c"
                />
            <br></br>
            <br></br>
            <p>C'est la fin de ce premier chapitre sur python, je vous donne rendez-vous au prochain chapitre, pour en apprendre plus.</p>
            <br></br>
            <br></br>

        <div>
            <br></br>
            <br></br>
            <a href="./cours-python_2" className="prochain">
                <button><p>prochain chapitre ></p></button>
            </a>
        </div>
        <br></br>
            <br></br>
            </div>
            </div>
      
    )
}

export default python_1
