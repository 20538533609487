import React from 'react'

import './app.css'

const Accueil = () => {
    return (
        <div className="App">
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h1 className="courss">Planeteia</h1>
            <br></br>
            <br></br>
            <br></br>
            <h4 className="courss">vous voulez apprendre à programmer ?</h4>
            <br></br>
            <br></br>
            <h4 className="courss">découvrir la physique et les nouvelles technologies ?{"\n"} </h4>
            <br></br>
            <br></br>
            votre voyage commence maintenant avec ce bouton :
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <a href="./Cours">
            <button className="abouton">Commencer</button>
            <h6 style={{fontSize: 2}}>charles dewandel</h6>
            </a>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            
        </div>
    )
}

export default Accueil;
