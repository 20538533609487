import React from 'react'
import './app.css'
import vscode from './VSCODE.png'
import { Link } from 'react-router-dom'
import python from './python_cours.png'
import imprimante from './imprimante2.jpg'


const Article = [
    {nom : imprimante, 
     titre : "Pourquoi vous devriez acheter une imprimante 3D",
     path  : "./imp3d"
    },
];

const pageArticles = Article.map((article) =>
<div>
           <br></br>
           <br></br>
           <div >
           <a href={article.path} style={{textDecoration: "none"}} >
           <div className="cour" style={{textDecoration: "none"}} >
           <img  src={article.nom} className="img_cour"/>
           <h4 className="TitreArticle">{article.titre}</h4>
           </div>
           </a>
           </div>
           <br></br>
           <br></br>
        </div>
);

const Articles = () => {
    return (
        
        <div className="App">
            <br></br>
           <br></br>
           <h1>Articles</h1>
           <br></br>
           <br></br>
           {pageArticles}
           <br></br>
           <br></br>
           
        </div>
    )
}

export default Articles
