import React from 'react'
import './cours.css'
import vscode_inst from './images/vs-code_installation.PNG'
import vscode_prem from './images/vscode_premier.PNG'
import vscode_extend from './images/vscode_extensions.PNG'
import vscode_winter from './images/vscode_winter.PNG'


function vscode() {
    return (
        <div className="App">
            <div className="cours">
            <h1>Vscode, installation et configuration</h1>
            <br></br>
            <br></br>
            <p>bienvenue dans ce premier cours de planeteia.com, et aujourd'hui on va parler de Vscode, 
            l' IDE magique de microsoft.</p>
            <br></br>
            <br></br>
            <p>pour commencer, on va expliquer pourquoi vscode est le meilleur IDE jamais créé en plusieurs points :</p>
            <ul>
            <li>Il est multi-plateforme </li>
            <li>une énorme communauté</li>
            <li>un nombre illimité d'extensions</li>
            <li>Le terminal et l'explorateur de fichiers intégrés</li>
            </ul>
            <br></br>
            <br></br>
            <h3>maintenant nous pouvons donc passer à l'installation :</h3>
            <br></br>
            <p>vous pouvez télécharger vscode à cette adresse </p>
            <br></br>
            <a href="https://code.visualstudio.com/" target="_blank">
            <p>https://code.visualstudio.com/</p>
            <br></br>
            <img src={vscode_inst} alt="installation" width="75%" height="32%" />
            </a>
            <br></br>
            <br></br>
            <h2>bases de l'interface</h2>
            <br></br>
            <img src={vscode_prem} alt="vscode_prem" width="75%" height="29%"/>
            <br></br>
            <br></br>
            <br></br>
            <h4>La barre latérale gauche </h4>
            <p>
            le premier onglet avec les deux feuilles sert d'explorateur de fichiers intégrés 
            <p>le deuxieme sert à chercher des mots et à les remplacer (presque jamais utilisé)</p>
            <p>le troisième sert à gérer votre source git si vous désirez
            partager votre travail sur git ou github</p> 
            <p>le quatrième sert a éxécuter votre programme, même si généralement on va
            éxécuter celui-ci dans votre terminal</p>
            </p>
            <p>le cinquième sert à installer les extensions</p>
            <p> et ceux tout en bas servent à se connecter a git ou microsoft et à accéder au paramètres</p>
            <br></br>
            <br></br>
            <h4>La barre du haut</h4>
            <p>
            le "File" sert comme dans presque tous les logiciels à créer et ouvrir des fichiers ainsi qu'à les sauver
            <p>Le deuxieme, "Edit" sert à  retourner en arrière, copier, coller, même si généralement on utilise les shortcuts</p>
            <p>Le troisième ne sert à rien, personnelement il ne m'a jamais servi</p> 
            <p>Le quatrième, "View", sert à modifier le layout et à lancer la palette de commandes</p>
            </p>
            <p>Le cinquième sert à éxécuter les programmes depuis la barre du haut</p>
            <p>Le "Terminal" sert à gérer le terminal</p>
            <p>Le "Help" sert à trouver de l'aide à propos de vscode</p>
            <br></br>
            <br></br>
            <h3>Extensions</h3>
            <br></br>
            <br></br>
            <img alt="vscode_extensions" src={vscode_extend}  width="75%" height="29%"/>
            <br></br>
            <br></br>
            <p>Maintenant que l'installation est faite et que nous avons compris le fonctionnement de l'interface,
                on peut passer à l'installation de certaines extensions.
            </p> 
            <br></br>
            <p>Les premières extensions à installer sont les auto-compléteurs de textes tabnine et kite.</p>
            <p>Ensuite vous pouvez installer ce qui vous sert pour votre développement tels que react snippets</p>
            <p>Et pour finir, les thèmes. le mien est le "Winter is coming" dont voici une image : </p>
            <br></br>
            <br></br>
            <img  src={vscode_winter} alt="vscode_winter" width="75%" height="29%"/>
            <br></br>
            <br></br>
            <p>C'est la fin de ce premier tutoriel sur le site Planeteia, merci pour votre attention, et a bientôt, j'espère !</p>
            <br></br>
            <br></br>
            </div>
            <br></br>
            <br></br>
        </div>
    )
}

export default vscode
