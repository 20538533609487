import React from 'react'
import './app.css'

const Contact = () => {
    return (
        <div className="App">
            <br></br>
           <h2>Contact</h2>
           <br></br>
           <br></br>
           <p>si vous voulez me contacter, vous pouvez me joindre à l'adresse aplinete@gmail.com</p>
           <br></br>
           <p>vous pouvez également me suivre sur instagram @planeteia</p> 
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
           <br></br>
  
        </div>
    )
}

export default Contact;
