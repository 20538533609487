import React, { useState } from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  SidebarData
} from './NavBarElements';
import { Link } from 'react-router-dom';
import './navbar.css'
import icon from './icon.ico'
import Accueil from './Accueil.js'
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { IconContext } from 'react-icons';

const SideNav = () => {
  return (
  <h1>hello</h1>
  );
};

const Navbar = () => {
  
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  return (
  

      <Nav>
         <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
          <Link to='#' className='menu-bars'>
            <FaIcons.FaBars onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
        <a href="./" className="lien">
        <img className="imgnavbar"src={icon} width="60" height="60" style={{float: 'left'}}/>
        </a>
        <NavMenu>
          <NavLink to='/Cours' activeStyle>
            Cours
          </NavLink>
          <NavLink to='/Articles' activeStyle>
            Articles
          </NavLink>
          <NavLink to='/Contact' activeStyle>
            Me contacter
          </NavLink>
          </NavMenu>
          
          {/* Second Nav */}
          {/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
        <NavBtn>
          <NavBtnLink to='/signin'>à venir</NavBtnLink>
        </NavBtn>
      </Nav>

  );
};

export default Navbar;
