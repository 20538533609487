import React from 'react'
import './cours.css'
import ReactAce from 'react-ace-editor';

const python_1 = () => {
    return (
        <div className="App">
        <h1 style={{margin:'100px'}}>Cours Python</h1>
        <div className="cours">
            <br></br>
            <br></br>
            <h2>2 : variables et opérations</h2>
            <br></br>
            <br></br>
            <h3>variables</h3>
            <ol>
            <br></br>
            <p>
                en python, les variables peuvent être représentées comme des tiroirs sur lesquels 
                nous aurions indiqué un certain type et dans lesquels seraient posés des papiers 
                avec écrit dessus un nombre, une phrase ou encore une matrice
            </p>
            <br></br>
            <li>Les différents types de variables</li>
            <br></br>
            <p>les types de variables les plus utilisées sont :
                <ul>
                    <li>les ints : 5, 10 ...</li>
                    <li>les strings : ""Hello World"</li>
                    <li>les floats : 5.5</li>
                    <li>les booléens : True, False</li>
                </ul>
            </p>
            <br></br>
            <p>bien sûr, il existe énormément de types de variables : les listes, les dictionnaires, 
                les complexes, dont nous parlerons dans les prochains chapitres.</p>
            <br></br>
            <li>Savoir utiliser les variables</li>
            <br></br>
            <p>pour utiliser les variables dans un code voici quelques exemples :</p>
            <br></br><br></br><br></br>
            <div style={{ borderRadius:5}}>
            <ReactAce
        mode="python"
        theme="monokai"
        setReadOnly={true}
        setValue="x=5 
        message = 'hello world!'
        print(x*5)
        print(message) "
        style={{height: 70, borderRadius:5, lineHeight: 1.3, paddingLeft: 100}}
// Let's put things into scope
      /></div>
            <br></br><br></br>
            <p>Le "x=5" ici définit la variable x comme étant égal à 5. Le message = "hello world!" 
            définit la variable message comme étant égal à la chaine de caractères "hello world!".
            les deux print affichent les variables.</p>
            </ol>
            <br></br>
            <br></br>
            <h3>Opérations :</h3>
            <br></br>
            <br></br>
            <p>Les opérations, vous les connaissez normalement déjà (+, x, :, -). néanmoins, en python, la multiplication
            s'écrit "*" et la division s'écrit "/". À part cela, utiliser les opérations en python est très facile. voici
            un exemple de code pour utiliser les opérations : 
            "a=2
            b=a*2-5+2*a print(4*b) ".
            
            ceci donne comme réponse 12.</p>
            <br></br><br></br><br></br>
            <div style={{ borderRadius:5}}>
            </div>

            <br></br>
            <br></br>
            <p>C'est déjà la fin de ce cours sur les variables et opérations, j'espère vous retrouver très bientôt sur planieteia, et je vous invite à aller
            voir le 3ème chapitre de ce cours à propos des fonctions. </p>
            <br></br>
        </div>
        <div>
            <br></br>
            <br></br>
            <a href="./" className="prochain">
                <button><p>prochain chapitre ></p></button>
            </a>
        </div>
        <br></br>
            <br></br>
        </div>
    )
}

export default python_1
