import React from 'react'
import './app.css'
import vscode from './VSCODE.png'
import { Link } from 'react-router-dom'
import python from './python_cours.png'
import imprimante from './imprimante2.jpg'
import { FaRegIdBadge } from 'react-icons/fa'
const Cour = [
    {
     nom : python, 
     titre : "Python ",
     path  : "./cours-python"
    },
    {
        nom : vscode, 
        titre : "Cours vscode",
        path  : "./vscode"
    },
    {
        nom : "voyage temps", 
        titre : "le voyage dans le temps est-il possible",
        path  : "./voyage_temps"
    }
];

const pageCours = Cour.map((course) =>
<div>
           <br></br>
           <br></br>
           <div >
           <a href={course.path} style={{textDecoration: "none"}} >
           <div className="cour" >
           <img  className="img_cour"src={course.nom} alt="vscode" />
           <h3 className="TitreCours">{course.titre}</h3>
           </div>
           </a>
           </div>
           <br></br>
           <br></br>
        </div>
);

const Cours = () => {
    return (
        <div className="App">
           <br></br>
           <br></br>
           <h1> cours</h1>
           <br></br>
           <br></br>
           <br></br>
           <div>{pageCours}</div>
           <br></br>
           
           
        </div>
    )
}

export default Cours
