import './App.css';
import Navbar from './navbar/index.js'
import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Accueil from './navbar/Accueil'
import Contact from './pages/Contact'
import About from './pages/About'
import Cours from './pages/Cours'
import Articles from './pages/Articles'
import vscode from './pages/Cours/vscode'
import python_1 from './pages/Cours/python_1'
import python_2 from './pages/Cours/python_2'
import imp3d from './pages/Cours/pkimprimante3d'
import voyage_temps from './pages/Cours/voyage_temps.js'



class App extends React.Component {
  render () { 
    return <div className="App" style={{height : '100%'}}>
     <Router>
      <Navbar />
      <Switch>
      <Route path='/' exact component={Accueil} />
      <Route path='/cours-python_2' exact component={python_2} />
      <Route path='/Contact' exact component={Contact} />
      <Route path='/vscode' exact component={vscode} />
      <Route path='/voyage_temps' exact component={voyage_temps} />
      <Route path='/cours-python' exact component={python_1} />
      <Route path='/imp3d' exact component={imp3d} />
      <Route path='/Cours' exact component={Cours} />
      <Route path='/Articles' exact component={Articles} />
      </Switch>       
      </Router> 
    </div>
  }
}
//<header className="App-header">
 //     </header>
export default App;
