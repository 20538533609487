import React from 'react'
import './cours.css'
import ReactPlayer  from 'react-player';

   


const voyage_temps = () => {
    return (
        <div className="App">
        <head><meta name="description" 
        content="Cours python"></meta>
        <title>Cours python</title></head>
        <h1 style={{margin:'100px'}}> Le voyage dans le temps est il possible ?</h1>
        <div className="cours">
            <br></br>
            <br></br>
            <h2>Introduction : </h2>
            <br></br>
            <br></br>
                <br></br>
                <p> Bonjour et aujourd'hui nous allons parler d'un des plus grands fantasmes de l'humanité
                , le voyage dans le temps. pour ce faire, il nous faut d'abord découvrir la 
                relativité restreinte, une des plus grandes découvertes d'Einstein. le niveau mathématique requis
                n'est pas plus compliqué que le théorème de pythagore. ce qui est compliqué avec la relativité
                restreinte, et une bonne partie de la physique en général, vient de cette citation :
                "le défi de la physique est d'expliquer le réel par l'impossible" en d'autres mots, 
                c'est insensé au premier abord. Si vous êtes prêt, nous pouvons commencer :</p>
                <br></br>
                <br></br>
                <br></br>
                <h3>relativité</h3>
                <br></br>
                <br></br>
                <p>Tout d'abord, pourquoi apprendre la relativité restreinte pour répondre à la question
                du voyage dans le temps ? Et bien car comme nous le verrons dans ce mini-cours, la relativité parle
                du temps comme une dimension (nous en discuterons plus tard) et introduit la vitesse de la lumiére comme
                une constante universelle, la limite cosmique de vitesse spatiale.</p>
                <br></br>
                <p>Le concept de relativité existait bien avant Einstein, et celui qui l'a formalisé en premier était galilée.
                il a détruit le concept de mouvement absolu. En effet, le mouvement rectiligne uniforme (que nous allons appeler
                MRU dans ce mini-cours), peut être égal quand on fait des calcul à aucun mouvement, car il ne requiert pas de force
                selon la première et la deuxième loi de Newton. Néanmoins ni Newton ni Galilée n'avaient parlé de la relativité du 
                temps. Ils considéraient que le temps était absolu. Même sans relativité, il est facile de démontrer que celui ci ne
                l'est pas : Imaginons qu'il n'existe dans l'univers, que trois planètes, sur lesquelles sont postées 1 une personne
                chacune. celles ci forment un triangle strictement quelconque. Si une des planètes explose, les habitants
                des deux planètes restantes mettront un temps défini à recevoir l'information, due à la vitesse finie de la lumière.
                Ètant donné que les trois planètes formaient un triangle strictement quelconque, les deux observateurs sont à une 
                distance des deux observateurs par rapport à la planète détruite est différente et donc le temps qui sera affiché 
                leur horloge au moment ou ils verront la planète exploser le sera aussi. Le temps mesuré est donc relatif.</p>
                <br></br>
                <p>Maintenant que tout ceci est fait, nous pouvons entrer dans le vif du sujet. Pour découvrir la 
                relativité restreinte, nous devons partir d'un postulat simple, mais non sans conséquences, "la vitesse de 
                la lumière est la même dans tous les référentiels et pour tous les observateurs." ce postulat a pour conséquence
                d'admettre que les objets en mouvement ont un temps dilaté et les longeurs contractées.</p>
                <br></br>
                <br></br>
                <h4>expérience de pensée 1</h4>
                <br></br>
                <p>Imaginons deux personnes dans une gare, une dans un train, et l'autre sur le quai. Ceux-ci ont chacun
                un dispositif particulier : un horloge à rayons lumineux. deux miroirs réfléchissant à 100% séparés d'un mètre, parfaitement
                parrallèle. Entre les deux, un rayon lumineux fait des aller-retour plus de 150 000 000 de fois par seconde</p>
                <br></br>
                <p>Si le train se déplace à une vitesse v par rapport au quai, l'observateur du quai, que nous allons nommer observateur B verra 
                le rayon lumineux doit parcourir une distance (1 + v^2)^1/2 150 000 000 de foirs par seconde. sa vitesse est donc plus grande.
                notre postulat est alors mis en défaut. Analysons cela maintenant à l'aide des mathématiques :
                la distance que parcoure la lumière selon l'observateur A (celui qui est dans le train) est ct (vitesse de la lumière x temps) 
                et pour l'observateur B  , la lumière parcourt une distance égale à d = ct' + vt' (v étant la vitesse du train). </p>
                <br></br>

        <div>
            <br></br>
            <br></br>
            <a href="./cours-python_2" className="prochain">
                <button><p>prochain chapitre ></p></button>
            </a>
        </div>
        <br></br>
            <br></br>
            </div>
            </div>
      
    )
}

export default voyage_temps