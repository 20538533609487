import React from 'react'
import './cours.css'
import axe_y from './images/Imprimante 3d site/axe y.jpg';
import axe_z from './images/Imprimante 3d site/axe z.jpg';
import extrudeur from './images/Imprimante 3d site/extrudeur.jpg';
import plateau from './images/Imprimante 3d site/plateau.jpg';

function imp3d() {
    return (
        <div className="App">
        <h1 style={{margin:'100px'}}>Pourquoi vous devriez acheter une imprimante 3D et laquelle : </h1>
        <div className="cours">
        <br></br>
        <p>Souvent, quand on évoque l'impression 3D, les gens imaginent une grosse machine, chère et
             qui requiert beaucoup d'expérience pour être utilisée.
             {"\n"}<br></br><br></br>
             En réalité, il existe des imprimante pas plus haute que 50 cm et qui ne coûtent pas plus
             de 150€ qui délivrent déjà une qualité d'impression qui force le respect. 
             Aujourd'hui, nous allons parler de la meilleure imprimante en dessous de 200€ selon
             Amazon, l'ender 3</p>
             <br></br>
             <br></br>
             <ol>
                 <h3><li>Comment ça fonctionne ?</li></h3>
                 <br></br>
                 <p>La ender 3 fonctionne par dépôt de plastique. elle appartient à la catégorie des 
                    imprimantes FDM ( il existe aussi des imprimantes qui 
                    fonctionnent par solidification de résine à l'aide d'UVs, dites SLA/SLS, qui 
                    délivrent une meilleure précision, mais qui sont plus chères et demandent plus d'entretien).
                    une partie appelé tête d'impression ou "hotend" en anglais fait chauffer le plastique qui est amené par un
                    pas à pas,
                    <br></br><br></br>
                    <img src={extrudeur} width="35%" height="25%" style={{ marginLeft:'35%', align: 'center', justifyContent:'center'}}/>
                    <br></br><br></br><br></br>
                    et est dirigée le long de trois axes X,Y,Z pour le déposer de façon à créer l'objet désiré.</p>
                    <br></br><br></br>
                    <div> <img src={axe_y} width="35%" height="25%" style={{ align: 'left', justifyContent:'center'}}/>
                    <img src={axe_z} width="35%" height="25%" style={{marginLeft:'25%', align: 'right', justifyContent:'right'}}/>
                      </div>
                      <br></br><br></br>
                    <p>le filament chauffé fond et est alors déposé sur un plateau lui-même chauffé</p>
                    <br></br><br></br><br></br><br></br>
                    <img src={plateau} width="35%" height="25%" style={{ marginLeft:'35%', align: 'center', justifyContent:'center'}}/>
                    <h3><li>Précisions sur l'imprimante</li></h3>
                    <br></br><br></br>
                    <p>cette imprimante coute en moyenne 150€ mais on peut la trouver à 130€ sur 
                    des sites chinois. le son moyen est de 69 DB et son volume d'impression est de 220x220x235mm. 
                    Si ces chiffres vous paraissent petits ce volume vous permettra d'imprimer plus ou moins tout ce que vous voulez.
                    </p>
                    <br></br><br></br>
                    <h3><li>Pourquoi cette imprimante ?</li></h3>
                    <br></br><br></br>
                    <p><ul>
                        <li>cette imprimante est peu chère par rapport à la qualité qu'elle délivre</li>
                        <li>elle est open-source</li>
                        <li>il y a une énorme communauté derrière cette imprimante</li>
                        </ul>
                        <br></br>
                        cette imprimante étant open-source et possédant une grande communauté l'améliorer est très facile, 
                        vous pouvez notamment voir certaines améliorations imprimées en 3D sur le photos ci-dessus.
                        </p>
                        <br></br><br></br>
                        <h3><li>Que peut-on faire avec une imprimante 3D ?</li></h3>
                        <ul>
                            <li>certains objets domestiques (un pot à plante auto-arrosant)</li>
                            <li>réalisations pour projet électronique (un robot imprimé en grande partie en 3D bientôt sur le site)</li>
                            <li>une autre imprimante 3D (reprap)</li>
                        </ul>
                        <br></br>
                        <p>si vous possédez déjà une imprimante 3D ou que cet article vous a convaincu, je vous donne rendez-vous au prochain article, qui vous explique quels paramètres choisir pour son ender 3</p>
             </ol>
        </div>
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <a href="./" className="prochain">
                <button><p>conseils pour imprimer avec l'ender 3</p></button>
            </a>
        </div>
        <br></br>
            <br></br>
        </div>
    )
}

export default imp3d
